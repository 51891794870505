<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2020-12-31 16:10:55
 * @LastEditors: AKJERRT 1617572569@qq.com
 * @LastEditTime: 2022-07-06 18:17:23
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'"
             style="top: 8px;"
             width="80%"
             v-model="visible"
             :footer="null"
             :maskClosable="false">
        <a-row :gutter="8">
            <a-col :span="6">
                <a-tree
                checkable
                checkStrictly
                :tree-data="treeData"
                :replace-fields="replaceFields"
                :auto-expand-parent="autoExpandParent"
                @check="onCheck"
                v-model="checkedKeys"
                :disabled="handle == 'check'" />
            </a-col>
            <a-col :span="18">
                <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                    <a-form-model-item label="品牌名称" prop="chName">
                        <a-input :disabled="handle == 'check'" v-model="rowData.chName" placeholder="请输入品牌名称"></a-input>
                    </a-form-model-item>
                  <a-form-model-item label="排序" prop="">
                    <a-input :disabled="handle == 'check'" v-model="rowData.sort" placeholder="请输入排序"></a-input>
                  </a-form-model-item>
                    <a-form-model-item label="品牌logo" prop="">
                        <QiniuUpload :disabled="handle == 'check'" type="image" :value.sync="rowData.logo"></QiniuUpload>
                        <div class="upload-hint-text">支持扩展名：.jpg .png</div>
                    </a-form-model-item>
                  <a-form-model-item label="品牌banner" prop="">
                    <QiniuUpload :disabled="handle == 'check'" type="image" :value.sync="rowData.banner"></QiniuUpload>
                    <div class="upload-hint-text">支持扩展名：.jpg .png</div>
                  </a-form-model-item>
                  <a-form-model-item label="状态" prop="flagEnable">
                    <a-switch :disabled="handle == 'check'" v-model="rowData.flagEnable" checked-children="启用" un-checked-children="禁用" default-checked/>
                  </a-form-model-item>
                  <a-form-model-item label="描述说明" prop="(remark">
                    <a-textarea  :disabled="handle == 'check'"  v-model="rowData.remark" placeholder="请输入描述说明"></a-textarea>
                  </a-form-model-item>
                </a-form-model>
            </a-col>
        </a-row>
      <div class="footer-bts" v-if="handle != 'check'">
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="sbmit" type="primary" :loading="loading" @click="toSubmit">提交</a-button>
      </div>
    </a-modal>
</template>

<script>
import { editProductBrand, selectByIdProductBrand, addProductBrand } from '../api/ProductBrandApi'
export default {
    data() {
        return {
            headers: {
                authorization: 'authorization-text',
            },
            visible: false,
            handle: 'add',
            rowData: {},
            treeData: [],
            autoExpandParent: true,
            loading: false,
            checkedKeys: [],
            selectedKeys: [],
            replaceFields: {
                children: 'children',
                title: 'name',
                key: 'id'
            },
            // 表单验证
            formRule: {
                chName: [
                    { required: true, message: '请输入品牌名称', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入描述说明', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {

      handleCancel() {
        this.rowData = {}
        // this.typeSpecTableData = []
        // this.typeSpecList = []
        // this.categoryIds = []
        // this.brandId = []
        this.visible = false
      },
        /**
         * 获取行数据
         */
        setRowData(row, handle){
                        console.log(JSON.parse(JSON.stringify(row)),"获取行数据" )

            this.handle = handle
            this.visible = true
            this.rowData = row
            this.checkedKeys = row.ids
            if(handle == 'add') {
                this.$set(this.rowData, 'flagEnable', true)
            }
            this.axios.get('/api/product/category/list').then(res => {
                this.treeData = res.body[0].children
                console.log(this.treeData)
            })
        },
        /**
         * 表单提交
         */
        toSubmit() {

            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                if (this.handle === "edit") {
                // this.rowData.ids = this.checkedKeys
                if (this.checkedKeys.checked) {
                    this.rowData.ids = this.checkedKeys.checked
                } else {
                    this.rowData.ids = this.checkedKeys
                }
                    
                } else if ((this.handle === "add")) {
                // this.rowData.ids = this.checkedKeys.checked
                if (this.checkedKeys.checked) {
                    this.rowData.ids = this.checkedKeys.checked
                } else {
                    this.rowData.ids = this.checkedKeys
                    console.log(this.checkedKeys,"表单提交",this.handle);
                    console.log("rowData", JSON.parse(JSON.stringify(this.rowData)) );
                }
                }
                
                const res = this.handle === 'add' ? await addProductBrand(this.rowData) : await editProductBrand(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        },
        // 上传
        handleChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                this.$message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
        onCheck(checkedKeys, info) {
            this.checkedKeys = checkedKeys.checked
        },
    },
    created() {
        this.axios.get(`/api/product/category/list?FlagEnable=true&flagDel=true`).then(res => {
            this.treeData = res.body[0].children
        })
    },

}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
</style>
